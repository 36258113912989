@import "../../scss/breakpoints.scss";
@import "../../scss/colors.scss";
@import "../../scss/typography.scss";

$sidebar-width-collapsed: 71px;
$sidebar-width-expanded: 260px;
$header-height: auto;

@mixin sidebarTransition($transitionArea) {
  transition: $transitionArea ease-in-out 330ms;
}

.containerLayout {
  flex: 1;
  @include sidebarTransition(grid-template-columns);
  display: grid;
  grid-template-columns: $sidebar-width-expanded 1fr;
  grid-template-rows: $header-height 1fr;
  grid-template-areas:
    "sidebar header"
    "sidebar content";

  &.isSidebarClose {
    grid-template-columns: $sidebar-width-collapsed 1fr;

    &:not(.isHoverSidebar) {
      .sidebarContainer {
        width: $sidebar-width-collapsed;

        .logo {
          width: 55px;
        }

        .menuItem > button {
          justify-content: center;

          & > p,
          &::after {
            display: none;
          }
        }

        .subMenu {
          display: none;
        }
      }
    }
  }

  .content {
    grid-area: content;
    padding: 8px 22px 24px;
    max-width: 100%;
    overflow: auto;
  }

  @include breakpoint-to(lg) {
    position: relative;
    grid-template-columns: 1fr !important;
    grid-template-areas:
      "header"
      "content";

    &.isSidebarClose {
      .sidebarContainer {
        transform: translateX(-100%);
        width: 0vw !important;
      }

      .menuItem > button {
        & > p,
        &::after {
          display: block !important;
        }
      }

      .subMenu {
        display: block !important;
      }
    }

    .sidebarContainer {
      transition: transform 560ms ease-in-out, width 800ms ease-in-out;
      width: 100vw;
      transform: translateX(0);
      position: fixed;
      inset: 0;
    }
  }
}

.sidebarContainer {
  @include sidebarTransition(width);
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  grid-area: sidebar;
  width: $sidebar-width-expanded;
  background: linear-gradient(
    45deg,
    map-get($gray, 700) 0%,
    map-get($gray, 600) 100%
  );
  padding: 17px 8px;
  box-sizing: border-box;
  height: 100%;
  position: fixed;
  z-index: 10;

  ul {
    margin: 0;
    padding-inline-start: 0;
    li {
      list-style: none;
    }
  }

  a,
  button {
    all: unset;
    white-space: nowrap;
  }

  &__close {
    display: none;
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: map-get($displaySizes, xs);
    color: $white;
  }

  .logo {
    @include sidebarTransition(width);
    width: 88px;
    height: auto;
    display: block;
    margin: 0 auto 19px;
  }

  .menuItemsContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .menuItem {
      color: $white;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;

      svg {
        min-width: 20px;
        min-height: 20px;
        color: $white;
        font-size: map-get($displaySizes, xs);
      }

      button {
        border-radius: 4px;
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 13px;
        width: 100%;
        padding: 8px;

        &:hover {
          background-color: map-get($gray, 700);
        }
      }

      .isActive {
        background-color: map-get($gray, 700);
      }

      p {
        max-width: 100vw;
        margin: 0;
        flex: 1;
      }

      .hasSubMenuIcon {
        color: $white;
        transform: translateY(15%) rotate(0deg);
        transition: transform ease-in-out 330ms;

        &.isExpanded {
          transform: rotate(-180deg);
          color: map-get($primary, 300);
        }
      }
    }
  }

  .subMenu {
    @extend .menuItemsContainer;
    color: map-get($primary, 100);
    align-self: baseline;
    max-height: 0px;
    margin-top: 0px;
    gap: 20px;
    padding-inline-start: 44px;
    overflow: auto;
    transition: max-height cubic-bezier(0.445, 0.05, 0.55, 0.95) 530ms,
      margin-top cubic-bezier(0.445, 0.05, 0.55, 0.95) 500ms;

    &.isExpanded {
      max-height: 100vh;
      margin-top: 12px;
    }

    &Item {
      user-select: none;
      cursor: pointer;
    }
  }

  .managerInfo {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    color: $white;
    margin-top: 64px;
    padding: 7px;

    &__title {
      line-height: 20px;
    }

    &__container {
      margin-bottom: 24px;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &__divider {
      width: 100%;
      height: 1px;
      background-color: map-get($gray, 600);
      margin-top: 8px;
    }

    &__phone,
    &__email {
      display: flex;
      gap: 8px;
      align-items: center;
      line-break: anywhere;

      svg {
        flex-shrink: 0;
        font-size: 22px;
      }
    }
  }

  @include breakpoint-to(lg) {
    &__close {
      display: block;
    }
  }
}
